<template>
    <v-container fluid>
        <v-row dense>
            <v-col cols="12" class="pb-0">
                <v-data-table
                    class="custom-data-table elevation-1"
                    dense
                    :headers="doctorHeaders"
                    :loading="$store.getters['doctor/loading']"
                    :items="$store.getters['doctor/doctors']"
                    :search="searchDoctor"
                >
                    <template v-slot:top>
                        <v-toolbar dense color="white" :elevation="1" height="30px">
                            <v-toolbar-title class="subtitle-2">Doctor List</v-toolbar-title>
                            <v-divider class="mx-4" inset vertical></v-divider>
                            <v-form class="custom-form">
                                <v-text-field
                                    v-model="searchDoctor"
                                    outlined
                                    dense
                                    hide-details
                                    placeholder="Search Doctor"
                                    append-icon="mdi-magnify"
                                    style="width:300px;"
                                >
                                </v-text-field>
                            </v-form>
                        </v-toolbar>
                    </template>
                    <template v-slot:[`item.action`]="{ item }">
                        <v-tooltip bottom>
							<template v-slot:activator="{ on }">
								<v-icon small @click="showDoctorProfile(item)" color="primary" v-on="on">mdi-eye</v-icon>
							</template>
							<span>View</span>
						</v-tooltip>
						<v-tooltip bottom>
							<template v-slot:activator="{ on }">
								<v-icon small @click="doctorId = item.id;$refs.confirmDialog.dialog = true" color="error" v-on="on" >mdi-delete-circle-outline</v-icon>
							</template>
							<span>Delete</span>
						</v-tooltip>
					</template>
                </v-data-table>
                <confirm-dialog ref="confirmDialog" @confirm="deleteDoctor"></confirm-dialog>
                <doctor-profile :data="doctorProfile" ref="doctorProfile"></doctor-profile>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import confirmDialog from "./../../components/Confirm";
import doctorProfile from "./../../components/DoctorProfile";
export default {
    name: 'DoctorEntry',

    components: {
		"confirm-dialog": confirmDialog,
		"doctor-profile": doctorProfile,
    },

    data: ()=> ({
        apiBasePath: window.apiBaseURL,
        date: new Date().toISOString().substr(0, 10),
        doctorHeaders: [
            { text: 'Id', value: 'code' },
            { text: 'Name', value: 'name' },
            { text: 'Mobile', value: 'mobile' },
            { text: 'Email', value: 'email' },
            { text: 'Specialist', value: 'specialist' },
            { text: 'Address', value: 'address' },
            { text: 'Action', value: 'action' },
        ],
        searchDoctor: '',
        doctors: [],
        doctorId: null,
        doctorProfile: {}
    }),

    created() {
        this.getDoctors();
    },

    methods: {
        getDoctors() {
            this.$store.dispatch('doctor/getDoctors');
        },

        deleteDoctor() {
            this.$store.dispatch('doctor/deleteDoctor', { id: this.doctorId });
            this.$refs.confirmDialog.dialog = false;
        },

        showDoctorProfile(doctor) {
            this.doctorProfile = doctor
            let days = doctor.days;
            this.doctorProfile.days = days;
            this.doctorProfile.days = (String(doctor.days).split(',')).join(', ');
            this.$refs.doctorProfile.dialog = true
        },
    }
}
</script>

<style lang="scss" scoped>
    .v-icon.v-icon {
       font-size: 18px !important;
       top: -3px !important;
    }
    .v-icon.v-icon[data-v-1f38b4e5] {
        font-size: 14px !important;
        top: 0px !important; 
    }
</style>